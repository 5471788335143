@mixin border-gradient($dir, $from, $to, $bg, $width) {
    background-image: linear-gradient($dir, $from, $to) !important;
    background-clip: padding-box;
    box-sizing: border-box;
    &::before {
      border-radius: inherit;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: $width solid transparent;
      transition: all .2s ease-in-out;
      background-color: $bg !important;
      background-clip: padding-box;
      box-sizing: inherit;
    }
  }
  
  @mixin background-gradient($dir, $from, $to) {
    background-image: -webkit-linear-gradient($dir, $from, $to),
  }
  
  @mixin text-gradient($dir, $from, $to) {
    background: -webkit-linear-gradient($dir, $from, $to);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }