.nav-wrapper {
    
    position: relative;
    
    padding: 6px 80px;

    .nav-container {
        max-width: 1080px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        padding: 10px 0;
    }

    .nav-left {
        display: flex;
        align-items: center;

        img {
            width: 65px;
        }

        .nav-logo {
            margin-right: 20px;
        }
    
        .nav-title {
            font-size: 28px;
            font-weight: bold;
        }
    }

    .nav-middle {
        display: flex;

        .link {
            margin: 0 20px;
        }

        .link-item, a {
          color: grey;
          text-decoration: none;
          font-weight: 500;
          font-size: 14px;
          cursor: pointer;
      }

      a:hover,
      .link-item:active,
      .link-item:hover,
      .link-active,
      .active,
      .router-link-exact-active {
        color: #fff;
        transition: 0.5s;
      }
    }
    
    .nav-right {
        display: flex;
    }

    &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        margin: 0 auto;
        background-image: -webkit-gradient(linear, 0 0, 100% 0, from(rgba(0, 0, 0, 0)), color-stop(0.5, $primary), to(rgba(0, 0, 0, 0)));
        background: linear-gradient(left, rgba(0, 0, 0, 0), $primary, rgba(0, 0, 0, 0));
        border: 0;
        min-width: 320px;
      }
}