.banner-wrapper {
  margin-top: 10px;
  background-color: $primary;

  p {
    margin: auto;
    padding: 10px 40px;
    font-weight: bold;
    max-width: 800px;
    text-align: center;
  }
}
