.home-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px;
    max-width: 1200px;
    margin: auto;

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px;
        max-width: 1200px;

        .row-left {
            margin-right: 40px;
        }
    }
}