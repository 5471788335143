.title-section {
    width: 450px;
    display: flex;
    align-items: center;

    .title-section-box {
        width: 450px;
    }

    .title {
        font-weight: 700;
        font-size: 55px;
        line-height: 100%;
        margin-bottom: 15px;
      }
    
    .gradient {
        @include text-gradient(45deg, $secondary, $primary);
        display: inline-block;
    }

    .icon-help {
        width: 20px;
        margin-bottom: 36px;
        margin-left: 0;
    }

    .link {
        font-weight: 700;
        font-size: 22px;
        margin-top: 20px;
    }
    
    .btn {
        width: 72%;
    }
}

