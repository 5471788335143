.footer-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
  
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      margin: 0 100px;
      background-image: -webkit-gradient(linear, 0 0, 100% 0, from(rgba(0, 0, 0, 0)), color-stop(0.5, $primary), to(rgba(0, 0, 0, 0)));
      background: linear-gradient(left, rgba(0, 0, 0, 0), $primary, rgba(0, 0, 0, 0));
      border: 0;
    }

    img {
        width: 30px;
        margin-right: 10px;
    }

    .highlight {
        color: $primary
    }
  }