$typography-font-family: "Roboto", "-apple-system", "Helvetica Neue", Helvetica, Arial, sans-serif !default;

// Assumes the browser default, typically `16px`
$body-font-size: 1rem;

$generic-border-radius: 12px;

// Colors

$primary: #F610FF;
$secondary: #2283E1;
$accent: #5F63EA;
$gray: #455A64;
$gray-text: #999;
$almostWhite: #eff5f6;

$graySuperLight: #F3F4F5;
$dark: #000000;
$lightGray: #9EB3BD;
$lightGrayNatural: #E9E9E9;
$naturalGray: #e7e7e7;

$positive: #0BF3A7;
$negative: #DF28AC;
$info: #2A7EE3;
$warning: #ffdf75;

$body-bg: #f4f4f4;

// Dark mode
$dark-page: #000000;
$dark-secondary: #292929;
$dark-button: #333;
$separator-dark-color: rgba(#fff, 0.15);

// Buttons
$button-border-radius: 5px;
$button-padding: 9px 17px;