@import 'variables';
@import 'mixins';
@import 'components';
@import 'vendors';

body {
    font-family: $typography-font-family;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000;
    color: #fff;
  }

.center {
  text-align: center;
}

.disabled {
  cursor: not-allowed;
}

.highlight {
  color: $primary;
}

.selected {
  //background: linear-gradient(90deg, #0c2d4d, #770a7b);
  @include border-gradient(135deg, $primary, $secondary, inherit, 3px);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 1000px $dark-secondary inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white;
}