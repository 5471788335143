.btn-container {
    position: relative;
    z-index: 99;
    background-color: #000;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    border-radius: $button-border-radius;
    @include border-gradient(135deg, $secondary, $primary, inherit, 2px);
    padding: 14px;
    border: none;
    margin-bottom: 20px;
    
    &:hover {
      background-image: none;
      background-color: $dark-secondary;
      cursor: pointer;
    }

    .btn {
      position: relative;
      z-index: 99;
      margin: auto;
    }
  }

  .btn-disabled {
    position: relative;
    z-index: 99;
    background-color: #000;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    border-radius: $button-border-radius;
    @include border-gradient(135deg, $secondary, $primary, inherit, 2px);
    padding: 14px;
    border: none;
    margin-bottom: 20px;
    filter: brightness(50%);
    
    &:hover {
      cursor: not-allowed;
    }

    .btn {
      position: relative;
      z-index: 99;
    }
  }

  .btn-gradient {
    position: relative;
    z-index: 99;
    background-color: #000;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    border-radius: $button-border-radius;
    @include border-gradient(135deg, $secondary, $primary, inherit, 2px);
    padding: 14px;
    border: none;
    margin-bottom: 20px;
    
    &:hover {
      background-image: none;
      background-color: $dark-secondary;
      cursor: pointer;
    }

    .btn {
      position: relative;
      z-index: 99;
      margin: auto;
      @include text-gradient(45deg, $primary, $secondary);
    }
  }