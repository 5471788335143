.swap-box-wrapper {

  .swap-box-container {
    max-width: 525px;
    min-width: 400px;
    margin: 10px auto 20px;
    border-radius: $generic-border-radius;
    background-color: black;
    position: relative;
    @include border-gradient(135deg, $primary, $secondary, inherit, 3px);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), -2px 4px 90px 10px rgba(34, 131, 225, 0.17);
    padding: 35px 50px;
    width: 450px;

    .swap-box {
      position: relative;

      .swap-box-title {
        font-size: 20px;
        font-weight: 500;
        position: relative;
        z-index: 99;
        color: #fff;
      }

      .icon-help {
        width: 16px;
        margin-left: 2px;
      }

      .info-text {
        @include text-gradient(45deg, $secondary, $primary);
        display: inline-block;
        margin-top: 2px;
        cursor: pointer;
      }

      .input-container {
        position: relative;
      }

      .input-swap {
        text-align: right;
        background-color: $dark-secondary;
        padding: 18px 20px 18px 130px;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        z-index: 100;
        width: 100%;
        box-sizing: border-box;
        color: #fff;
        border-radius: $generic-border-radius;
        outline: $dark-secondary;
        border: none;
        transition: all 0.36s cubic-bezier(0.4, 0, 0.2, 1);
        margin: 6px 0 20px 0;
    
        &:focus {
            outline: 2px solid $lightGray;
        }
      }

      .light-text {
        color: $naturalGray;
        //margin-bottom: 20px;
      }

      #outputBtn {
        //transform: translate(56px, -19px);
        position: relative;
        z-index: 102;
      }
    }
  }
}

.route-container {
  border-radius: $generic-border-radius;
  background-color: $dark-secondary;
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  cursor: pointer;

  .routing {
    color: $gray-text;
    margin-inline-start: 0;
    font-weight: 500;
    font-size: 12px;
  }
}

.settings-button {
  border-radius: $generic-border-radius;
  background-color: $dark-button;
  padding: 18px 24px;
  flex-grow: 1;
  text-align: center;
  //margin: 16px;
  font-weight: 500;
  font-size: 18px;
  position: relative;
  cursor: pointer;
}

#middle-button {
  margin: 0 10px;
}

.position-above {
  position: relative;
  z-index: 1000;
}