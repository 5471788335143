.stake-box-section {
    max-width: 525px;
    min-width: 400px;
    margin-right: 40px;
}

.stake-box-container {
    border-radius: $generic-border-radius;
    background-color: black;
    position: relative;
    //z-index: 1;
    @include border-gradient(135deg, $primary, $secondary, inherit, 3px);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), -2px 4px 90px 10px rgba(34, 131, 225, 0.17);
    padding: 35px 50px;
    width: 450px;

    .stake-box {
        position: relative;
        //z-index: 99;
    }

    .stake-box-title {
      font-size: 20px;
      font-weight: 500;
      position: relative;
      z-index: 99;
      color: #fff;
    }

    .icon-help {
      width: 20px;
      margin-left: 4px;
  }

    .stake-btn {
        position: relative;
        //z-index: 99;
        cursor: pointer;
    }
}

.input-label {
    position: absolute;
    font-size: 20px;
    transform: translate(290px, 42px) scale(1);
    z-index: 102;
}

#xrun-label {
  transform: translate(280px, 42px) scale(1);
}

.input-stake {
    background-color: $dark-secondary;
    padding: 18px 112px 18px 64px;
    font-size: 26px;
    position: relative;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
    background-image: url('../../assets/img/run-logo.svg');
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: 244px 14px;
    color: #fff;
    border-radius: $generic-border-radius;
    outline: $dark-secondary;
    border: none;
    transition: all 0.36s cubic-bezier(0.4, 0, 0.2, 1);
    margin: 20px 0px;

    &:focus {
        outline: 2px solid $lightGray;
    }
}

#xrun-input-stake {
  background-position: 234px 14px;
}

.max-btn {
    font-size: 14px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    z-index: 102;
    color: $lightGrayNatural;
    border: none;
    padding: 5px 6px;
    font-weight: 500;
    transform: translate(16px, 40px);
    transition: all 0.36s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        background-color: rgb(27, 27, 27);
        cursor: pointer;
    }
}