.stat-box-section {
    max-width: 525px;
    min-width: 400px;
  }

.stat-box {
    border-radius: $generic-border-radius;
    background-color: black;
    position: relative;
    z-index: 1;
    @include border-gradient(135deg, $primary, $secondary, inherit, 3px);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), -2px 4px 90px 10px rgba(34, 131, 225, 0.17);
    height: 560px;
    width: 450px;
    padding: 35px 50px;

    .box-section {
        margin: 18px 0;
    }

    .title {
        font-size: 28px;
        font-weight: 700;
        position: relative;
        z-index: 99;
        color: #fff;
    }

    .separator {
        border-bottom: 2px solid rgb(105, 30, 124);
        position: relative;
        z-index: 99;
        margin-top: 20px;
    }

    .stat-title {
        font-size: 20px;
        font-weight: 500;
        position: relative;
        z-index: 99;
        color: #fff;
        white-space: pre-wrap;
    }

    .highlight {
        color: $secondary;
    }

    .stat-title-gradient {
        @include text-gradient(45deg, $secondary, $primary);
        display: inline-block;
    }

    .stat-value {
        font-size: 24px;
        font-weight: bold;
        position: relative;
        z-index: 99;
        color: #fff;
        margin: 4px 0;
    }

    .stat-value__usd {
        color: #666;
        position: relative;
        z-index: 99;
    }

    .subtitle-gradient {
        @include text-gradient(45deg, $primary, $secondary);
        display: inline-block;
        position: relative;
        z-index: 99;
        margin-bottom: 10px;
    }

    .calc-input {
        background-color: $dark-secondary;
        padding: 20px 20px 20px 80px;
        font-size: 24px;
        position: relative;
        z-index: 100;
        width: 100%;
        box-sizing: border-box;
        background-image: url('../../assets/img/run-logo.svg');
        background-repeat: no-repeat;
        background-size: 40px;
        background-position: 20px 15px;
        color: #fff;
        border-radius: $generic-border-radius;
        outline: $dark-secondary;
        border: none;
        transition: all 0.36s cubic-bezier(0.4, 0, 0.2, 1);
        &:focus {
            outline: 2px solid $lightGray;
        }
    }
}